import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrash, FaToggleOn, FaToggleOff } from 'react-icons/fa';

interface Material {
  id: number;
  name: string;
  price: string;
  image: string;
  in_stock: boolean;
}

const Admin: React.FC = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const [newMaterialName, setNewMaterialName] = useState('');
  const [newMaterialPrice, setNewMaterialPrice] = useState('');
  const [newMaterialImage, setNewMaterialImage] = useState<File | null>(null);
  const [updatePrice, setUpdatePrice] = useState<{ [key: number]: string }>({});
  const [pricePerMile, setPricePerMile] = useState('');

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/materials`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setMaterials(response.data);
    } catch (error) {
      console.error('Error fetching materials:', error);
    }
  };

  const handleAddMaterial = async () => {
    try {
      const formData = new FormData();
      formData.append('name', newMaterialName);
      formData.append('price', newMaterialPrice);
      if (newMaterialImage) {
        formData.append('image', newMaterialImage);
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/materials`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Material added:', response.data);
      setNewMaterialName('');
      setNewMaterialPrice('');
      setNewMaterialImage(null);
      fetchMaterials();
    } catch (error) {
      console.error('Error adding material:', error);
      alert('Failed to add material. Please try again later.');
    }
  };

  const handleUpdateMaterialPrice = async (id: number) => {
    const price = parseFloat(updatePrice[id]);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      console.log(`Updating price for material ${id} to ${price}`);
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/materials/${id}`, { price }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Server response:', response.data);
      setMaterials(materials.map(material => material.id === id ? { ...material, price: price.toFixed(2) } : material));
      setUpdatePrice({ ...updatePrice, [id]: '' });
    } catch (error) {
      console.error('Error updating price:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      alert('Failed to update material price. Please try again later.');
    }
  };

  const handleDeleteMaterial = async (id: number) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/materials/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Delete response:', response.data);
      if (response.data.success) {
        setMaterials(materials.filter(material => material.id !== id));
        alert('Material deleted successfully');
      } else {
        throw new Error('Failed to delete material');
      }
    } catch (error) {
      console.error('Error deleting material:', error);
      alert('Failed to delete material. Please try again later.');
    }
  };

  const handleUpdatePricePerMile = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/update-price-per-mile`,
        { price: parseFloat(pricePerMile) },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      console.log('Server response:', response.data);
      alert('Price per mile updated successfully');
      setPricePerMile('');
    } catch (error) {
      console.error('Error updating price per mile:', error);
      if (axios.isAxiosError(error)) {
        alert(`Failed to update price per mile: ${error.response?.data || error.message}`);
      } else {
        alert('Failed to update price per mile');
      }
    }
  };

  const handleToggleStock = async (id: number, currentStockStatus: boolean) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      console.log(`Toggling stock for material ${id} from ${currentStockStatus} to ${!currentStockStatus}`);
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/materials/${id}/stock`,
        { in_stock: !currentStockStatus },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      console.log('Server response:', response.data);
      setMaterials(materials.map(material =>
        material.id === id ? { ...material, in_stock: !currentStockStatus } : material
      ));
    } catch (error) {
      console.error('Error updating stock status:', error);
      alert('Failed to update stock status. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white pt-20">
      <div className="container mx-auto p-4 md:p-8">
        <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-500">
          Admin Dashboard
        </h1>

        {/* Price Per Mile Section */}
        <div className="bg-gray-800 rounded-xl p-4 md:p-6 mb-8 shadow-lg">
          <h2 className="text-xl md:text-2xl font-bold mb-4 text-blue-400">Update Price Per Mile</h2>
          <div className="flex flex-col md:flex-row items-center">
            <input
              type="number"
              step="0.01"
              value={pricePerMile}
              onChange={(e) => setPricePerMile(e.target.value)}
              placeholder="New price per mile"
              className="flex-grow bg-gray-700 text-white border-none rounded-lg p-3 mb-4 md:mb-0 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleUpdatePricePerMile}
              className="w-full md:w-auto bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Update
            </button>
          </div>
        </div>

        {/* Materials Section */}
        <div className="bg-gray-800 rounded-xl p-4 md:p-6 mb-8 shadow-lg">
          <h2 className="text-xl md:text-2xl font-bold mb-4 text-blue-400">Materials</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <input
              type="text"
              placeholder="Material Name"
              value={newMaterialName}
              onChange={(e) => setNewMaterialName(e.target.value)}
              className="bg-gray-700 text-white border-none rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="number"
              placeholder="Material Price"
              value={newMaterialPrice}
              onChange={(e) => setNewMaterialPrice(e.target.value)}
              className="bg-gray-700 text-white border-none rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                if (e.target.files) {
                  setNewMaterialImage(e.target.files[0]);
                }
              }}
              className="bg-gray-700 text-white border-none rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-600"
            />
            <button
              onClick={handleAddMaterial}
              className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <FaPlus className="mr-2" /> Add Material
            </button>
          </div>
          <ul className="space-y-4 max-h-96 overflow-y-auto">
            {materials.map((material) => (
              <li key={material.id} className="bg-gray-700 rounded-lg p-4 shadow hover:shadow-lg transition duration-300 ease-in-out">
                <div className="flex flex-col md:flex-row items-center justify-between">
                  <div className="flex items-center space-x-4 mb-4 md:mb-0">
                    <img src={material.image} alt={material.name} className="w-16 h-16 object-cover rounded-lg" />
                    <div>
                      <p className="font-bold text-lg">{material.name}</p>
                      <p className="text-gray-400">
                        Price: ${material.price !== null && !isNaN(parseFloat(material.price)) ? parseFloat(material.price).toFixed(2) : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
                    <button
                      onClick={() => handleToggleStock(material.id, material.in_stock)}
                      className={`text-white p-2 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-2 ${
                        material.in_stock ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                      }`}
                    >
                      {material.in_stock ? <FaToggleOn /> : <FaToggleOff />}
                    </button>
                    <input
                      type="number"
                      placeholder="New Price"
                      value={updatePrice[material.id] || ''}
                      onChange={(e) => setUpdatePrice({ ...updatePrice, [material.id]: e.target.value })}
                      className="bg-gray-600 text-white border-none rounded-lg p-2 w-24 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      onClick={() => handleUpdateMaterialPrice(material.id)}
                      className="bg-yellow-500 text-white p-2 rounded-lg hover:bg-yellow-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteMaterial(material.id)}
                      className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Admin;