import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto p-12 pt-24 bg-white shadow-md rounded-lg">
      <h1 className="text-4xl font-bold mb-8 text-center">Terms of Service</h1>
      <p className="mb-6 text-lg">
        Welcome to Latin Transport Inc. By accessing our website and using our services, you agree to the following terms and conditions.
      </p>
      <p className="mb-6 text-lg">
        <strong>Service Agreement:</strong> Our services include material supply, land leveling, excavation, tree clearing, and more. Detailed descriptions of each service can be found on our website.
      </p>
      <p className="mb-6 text-lg">
        <strong>Payment Terms:</strong> Payment is due upon order placement. We accept various payment methods, including credit cards and ACH.
      </p>
      <p className="mb-6 text-lg">
        <strong>Cancellation Policy:</strong> Orders canceled within 48 hours of the scheduled delivery window are non-refundable. Cancellations prior to this window must be communicated promptly to avoid charges.
      </p>
      <p className="mb-6 text-lg">
        <strong>Liability for Property Damage:</strong> Our company is not responsible for any damage to driveways or property during delivery. Customers must ensure their property can accommodate delivery conditions.
      </p>
      <p className="mb-6 text-lg">
        <strong>Policy Amendments:</strong> This policy may be revised to reflect changes in business operations or legal requirements. Customers will be notified of any significant changes.
      </p>
    </div>
  );
};

export default TermsOfService;
