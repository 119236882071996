import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface Material {
  id: number;
  name: string;
  price: string;
  image: string;
  in_stock: boolean;
}

const Materials: React.FC = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchMaterials = async (retryCount = 0) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      console.log('Fetching materials...');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/materials`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Fetched materials:', response.data);
      setMaterials(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching materials:', error);
      if (retryCount < 3) {
        console.log(`Retrying in 2 seconds... (Attempt ${retryCount + 1} of 3)`);
        setTimeout(() => fetchMaterials(retryCount + 1), 2000);
      } else {
        setError('Failed to fetch materials after multiple attempts.');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchMaterials();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (isLoading) return <div className="min-h-screen bg-gray-300 flex items-center justify-center">Loading materials...</div>;
  if (error) return <div className="min-h-screen bg-gray-300 flex items-center justify-center text-red-500">{error}</div>;

  return (
    <div className="min-h-screen bg-gray-300 flex flex-col items-center pt-24">
      <div className="container mx-auto p-8">
        <h1 className="text-5xl font-bold mb-8 text-gray-800 text-center">Our Materials</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {materials.map((material) => (
            <div key={material.id} className={`max-w-sm rounded overflow-hidden shadow-lg bg-white ${material.in_stock !== false ? 'hover:shadow-xl transform transition duration-300 hover:scale-105' : 'opacity-50'}`}>
              {material.in_stock !== false ? (
                <Link to={`/order?material=${material.name}`}>
                  <img className="w-full h-48 object-cover" src={material.image} alt={material.name} />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 text-gray-800">{material.name}</div>
                    <p className="text-gray-700 text-base">${parseFloat(material.price).toFixed(2)}</p>
                  </div>
                </Link>
              ) : (
                <>
                  <img className="w-full h-48 object-cover" src={material.image} alt={material.name} />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 text-gray-800">{material.name}</div>
                    <p className="text-gray-700 text-base">${parseFloat(material.price).toFixed(2)}</p>
                    <p className="text-red-500 font-bold mt-2">Out of Stock</p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Materials;
