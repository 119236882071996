import React from 'react';

const RefundPolicy: React.FC = () => {
  return (
    <div className="container mx-auto p-12 pt-24 bg-white shadow-md rounded-lg">
      <h1 className="text-4xl font-bold mb-8 text-center">Refund Policy</h1>
      <p className="mb-6 text-lg">
        <strong>No Refunds Post-Delivery:</strong> All sales are final upon delivery. Given the nature of our products, returns or refunds are not feasible. Customers are advised to verify their orders before finalization.
      </p>
      <p className="mb-6 text-lg">
        <strong>Delivery and Access Requirements:</strong> In cases where delivery is unsuccessful due to site inaccessibility or customer absence, a delivery inconvenience fee may be charged. It is the customer’s responsibility to ensure clear and safe access for delivery. Delivery will need to be rescheduled, and refunds or cancellations will not be honored.
      </p>
      <p className="mb-6 text-lg">
        <strong>Cancellation Policy:</strong> Orders canceled within 48 hours of the scheduled delivery window are non-refundable. Cancellations prior to this window must be communicated promptly to avoid charges.
      </p>
      <p className="mb-6 text-lg">
        <strong>Liability for Property Damage:</strong> Our company is not responsible for any damage to driveways or property during delivery. Customers must ensure their property can accommodate delivery conditions.
      </p>
      <p className="mb-6 text-lg">
        <strong>Policy Amendments:</strong> This policy may be revised to reflect changes in business operations or legal requirements.
      </p>
    </div>
  );
};

export default RefundPolicy;
