import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCart } from '../../CartContext';

interface CompletionProps {
  message?: string;
}

const Completion: React.FC<CompletionProps> = ({ message }) => {
  const { clearCart } = useCart();
  const location = useLocation();
  const { orderId, orderDate } = location.state || {};

  useEffect(() => {
    clearCart();
  }, [clearCart]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white p-6 shadow-lg rounded-lg max-w-md w-full text-center">
        <h1 className="text-3xl font-bold mb-4">Thank you!</h1>
        <p className="text-lg mb-6">{message || 'Invoice has been emailed to you.'}</p>
        {orderId && (
          <div className="mb-4">
            <p className="text-sm text-gray-500">Order ID:</p>
            <p className="text-lg font-semibold">{orderId}</p>
          </div>
        )}
        {orderDate && (
          <div className="mb-4">
            <p className="text-sm text-gray-500">Order Date:</p>
            <p className="text-lg font-semibold">{orderDate}</p>
          </div>
        )}
        <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default Completion;