import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Services from './components/Services';
import Materials from './components/Materials';
import LandLeveling from './components/LandLeveling';
import TreeClearing from './components/TreeClearing';
import RetentionPonds from './components/RetentionPonds';
import Order from './components/Order';
import VerifyPin from './components/VerifyPin';
import Admin from './components/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import { CartProvider } from './CartContext';
import CartPopup from './components/CartPopup';
import useScrollToTop from './hooks/useScrollToTop';
import QuantityCalculator from './components/QuantityCalculator';
import Payment from "./components/Payments/Payment";
import Completion from "./components/Payments/Completion";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FAQ from './components/FAQ';
import TermsOfService from './components/TermsOfService';
import RefundPolicy from './components/RefundPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import ZipCodeEntry from './components/ZipCodeEntry';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const AppContent: React.FC = () => {
  const [isCartOpen, setIsCartOpen] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const pingServer = async () => {
      try {
        console.log('Pinging server...');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/ping`);
        console.log('Server response:', response.data);
      } catch (error) {
        console.error('Error pinging server:', error);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            console.error('Request data:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    pingServer();
  }, []);

  const handleCheckout = () => {
    setIsCartOpen(false);
    navigate('/checkout');
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  useScrollToTop();

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar onCartToggle={toggleCart} />
      <div className="flex-grow">
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/materials" element={<Materials />} />
            <Route path="/land-leveling" element={<LandLeveling />} />
            <Route path="/tree-clearing" element={<TreeClearing />} />
            <Route path="/retention-ponds" element={<RetentionPonds />} />
            <Route path="/zip-code" element={<ZipCodeEntry />} />
            <Route path="/order" element={<Order />} />
            <Route path="/quantity-calculator" element={<QuantityCalculator />} />
            <Route path="/verify-pin" element={<VerifyPin setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/checkout" element={<Payment />} />
            <Route path="/completion" element={<Completion />} />
            <Route
              path="/admin"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated} element={Admin} />
              }
            />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Elements>
      </div>
      <Footer />
      <CartPopup isOpen={isCartOpen} onClose={toggleCart} onCheckout={handleCheckout} />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <CartProvider>
      <Router>
        <AppContent />
      </Router>
    </CartProvider>
  );
};

export default App;
