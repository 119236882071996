import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useCart } from '../../CartContext';
import axios from 'axios';  // Make sure to import axios

const Payment: React.FC = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);  // Add error state
  const { cart, getTotal } = useCart();
  const total = getTotal();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/config`);
        const { publishableKey } = response.data;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error("Error fetching Stripe publishable key:", error);
        setError("Failed to load payment configuration. Please try again later.");
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/create-payment-intent`,
          { items: cart, total: getTotal() }
        );
        setClientSecret(response.data.clientSecret);
        setPaymentIntentId(response.data.paymentIntentId);
      } catch (error) {
        console.error('Error creating PaymentIntent:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
        setError('Failed to initialize payment. Please try again later.');
      }
    };
  
    if (cart.length > 0) {
      createPaymentIntent();
    }
  }, [cart, getTotal]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      {clientSecret && stripePromise && paymentIntentId ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm clientSecret={clientSecret} paymentIntentId={paymentIntentId} cart={cart} total={total} />
        </Elements>
      ) : (
        <div>Loading payment form...</div>
      )}
    </>
  );
};

export default Payment;