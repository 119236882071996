import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CartItem } from '../../CartContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface CustomerInfo {
  name: string;
  email: string;
  address: string;
  city: string;
  state: string;
}

interface CheckoutFormProps {
  clientSecret: string;
  paymentIntentId: string;
  cart: CartItem[];
  total: number;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ clientSecret, paymentIntentId, cart, total }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState<CustomerInfo>({
    name: '',
    email: '',
    address: '',
    city: '',
    state: ''
  });

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsProcessing(true);
    setMessage(null);

    if (!stripe || !elements) {
      setMessage("Stripe.js hasn't loaded yet. Please try again.");
      setIsProcessing(false);
      return;
    }

    try {
      console.log('Confirming payment with Stripe...');
      const { error: confirmError, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });
  
      if (confirmError) {
        // ... existing error handling ...
      } else if (paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded, calling confirm-payment endpoint');
        try {
          console.log('Sending data to confirm-payment:', { paymentIntentId: paymentIntent.id, customer: formData });
          const confirmResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/confirm-payment`, {
            paymentIntentId: paymentIntent.id,
            customer: formData,
          });
          console.log('Confirm payment response:', confirmResponse.data);
  
          if (confirmResponse.data.success) {
            setMessage('Payment succeeded and invoice has been sent!');
            // Add the navigation code here
            navigate('/completion', { 
              state: { 
                orderId: paymentIntent.id, 
                orderDate: new Date().toLocaleDateString() 
              } 
            });
          } else {
            setMessage(`Payment succeeded, but there was an issue: ${confirmResponse.data.error || 'Unknown error'}`);
          }
        } catch (confirmError) {
          // ... existing error handling ...
        }
      } else {
        // ... existing code ...
      }
    } catch (error) {
      // ... existing error handling ...
    }
  
    setIsProcessing(false);
  };

  

  return (
    <div className="checkout-form container mx-auto py-8 flex flex-wrap md:flex-nowrap mt-20">
      <div className="md:w-1/3 bg-gradient-to-br from-white to-gray-100 p-6 shadow-lg rounded-xl mb-8 md:mr-8 border border-gray-200">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">Order Summary</h2>
        <ul className="space-y-4 mb-6">
          {cart.map((item, index) => (
            <li key={index} className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex justify-between items-center">
                <span className="font-semibold text-gray-800">{item.name}</span>
                <span className="text-gray-600 bg-gray-100 px-2 py-1 rounded-full text-sm">
                  x {item.quantity}
                </span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <span className="text-gray-500 text-sm">Unit Price</span>
                <span className="font-medium text-gray-700">${item.price.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mt-1">
                <span className="text-gray-500 text-sm">Subtotal</span>
                <span className="font-medium text-gray-700">${(item.price * item.quantity).toFixed(2)}</span>
              </div>
              {item.deliveryMethod === 'delivery' && item.deliveryFee && (
                <div className="flex justify-between items-center mt-2 text-sm">
                  <span className="text-gray-500">Delivery Fee:</span>
                  <span className="text-gray-700 font-medium">
                    ${typeof item.deliveryFee === 'number' ? item.deliveryFee.toFixed(2) : item.deliveryFee}
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="border-t pt-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600">Subtotal:</span>
            <span className="font-medium text-gray-800">
              ${cart.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2)}
            </span>
          </div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600">Delivery Fees:</span>
            <span className="font-medium text-gray-800">
              ${cart.reduce((sum, item) => sum + (item.deliveryMethod === 'delivery' && typeof item.deliveryFee === 'number' ? item.deliveryFee : 0), 0).toFixed(2)}
            </span>
          </div>
          <div className="flex justify-between items-center mt-4 pt-4 border-t">
            <span className="font-bold text-lg text-gray-800">Total:</span>
            <span className="font-bold text-xl text-blue-600">${total.toFixed(2)}</span>
          </div>
        </div>
      </div>

      <div className="md:w-2/3 bg-white p-4 shadow-md rounded-lg">
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-medium text-gray-700">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <PaymentElement id="payment-element" />
          <button
            disabled={isProcessing || !stripe || !elements}
            id="submit"
            className="mt-4 w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 disabled:opacity-50"
          >
            <span id="button-text">
              {isProcessing ? 'Processing...' : 'Pay now'}
            </span>
          </button>
          {message && <div id="payment-message" className="mt-4 text-red-600">{message}</div>}
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;