// src/About.tsx
import React from 'react';
import officeImage from '../images/Office.jpg';
import reyImage from '../images/Reysuit.jpg';
import momImage from '../images/MomPic.jpg';

const About: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center pt-24 bg-gray-300 text-white">
      <div className="container mx-auto p-8">
        {/* Company Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2 flex justify-center md:justify-start mb-8 md:mb-0">
            <img 
              src={officeImage} 
              alt="Office" 
              className="w-full h-auto max-w-lg rounded-lg shadow-2xl transition-transform duration-300 hover:rotate-3 hover:scale-110"
            />
          </div>
          <div className="md:w-1/2 text-left">
            <h1 className="text-5xl font-bold mb-6 drop-shadow-lg text-gray-700">About Us</h1>
            <p className="text-lg bg-blue-900 bg-opacity-80 p-6 rounded-lg shadow-md transition-opacity duration-300 hover:bg-opacity-70 text-white">
              Welcome to Latin Transport Inc, a family-owned company based in Houston, Texas. We are dedicated to providing top-notch transportation services with a personal touch. Our mission is to ensure that every delivery is handled with care and precision, reflecting our commitment to excellence and our passion for serving our community.
            </p>
          </div>
        </div>

        {/* President Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2 text-left order-2 md:order-1">
            <h2 className="text-4xl font-bold mb-4 drop-shadow-lg text-gray-700">Reynaldo Remedios</h2>
            <p className="text-lg bg-blue-900 bg-opacity-80 p-6 rounded-lg shadow-md transition-opacity duration-300 hover:bg-opacity-70 text-white">
              Reynaldo Remedios, our esteemed President, has over 10 years of experience in the excavation and transportation industry. Known for his hands-on approach, Reynaldo is present at every job site, ensuring that our high standards are met consistently. His expertise and dedication have been instrumental in building our reputation for reliability and excellence.
            </p>
          </div>
          <div className="md:w-1/2 flex justify-center md:justify-start mb-8 md:mb-0 order-1 md:order-2">
            <img 
              src={reyImage} 
              alt="Reynaldo Remedios" 
              className="w-full h-auto max-w-lg rounded-full shadow-2xl transition-transform duration-300 hover:rotate-3 hover:scale-110"
            />
          </div>
        </div>

        {/* Vice President Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2 flex justify-center md:justify-start mb-8 md:mb-0">
            <img 
              src={momImage} 
              alt="Anel Garduno" 
              className="w-full h-auto max-w-lg rounded-full shadow-2xl transition-transform duration-300 hover:rotate-3 hover:scale-110"
            />
          </div>
          <div className="md:w-1/2 text-left">
            <h2 className="text-4xl font-bold mb-4 drop-shadow-lg text-gray-700">Anel Garduno</h2>
            <p className="text-lg bg-blue-900 bg-opacity-80 p-6 rounded-lg shadow-md transition-opacity duration-300 hover:bg-opacity-70 text-white">
              Anel Garduno, our Vice President, is the backbone of our logistics, finance, and customer service operations. With her exceptional organizational skills and dedication, Anel ensures that our business runs smoothly, and our clients receive the best possible service.
            </p>
          </div>
        </div>

        {/* Mission Section */}
        <div className="bg-blue-900 bg-opacity-80 py-12 rounded-lg shadow-md transition-opacity duration-300 hover:bg-opacity-70 w-full text-center text-white">
          <h2 className="text-4xl font-bold mb-4">Our Mission</h2>
          <p className="text-xl leading-relaxed mx-8 md:mx-20">
            Any building project requires excavation. From pools to driveways to septic tanks and basements, at Latin Transport Inc we are committed to providing the best service for all of our clients, with proven work methods. Residential or commercial, large or small, there’s no project we’ll turn down. And, with competitive rates as well as time-efficient practices, our clients know they can count on us to get jobs done up to the highest standards, within budget, by deadline. As a full-service company, we also offer rock breaking and removal as well as hauling services. So, when you’re looking for an excavation team that does it all, give Latin Transport Inc a call.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
