import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ZipCodeEntry: React.FC = () => {
  const [zipCode, setZipCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/calculate-distance`, { zipCode });
      const { distance, deliveryFee } = response.data;
      navigate('/order', { state: { distance, deliveryFee, customerZipCode: zipCode } });
    } catch (error) {
      console.error('Error calculating distance:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-300 flex flex-col items-center justify-center">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Enter Your Zip Code</h2>
        <input
          type="text"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          className="w-full p-2 border rounded mb-4"
          placeholder="Enter zip code"
          required
        />
        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
          Continue to Order
        </button>
      </form>
    </div>
  );
};

export default ZipCodeEntry;