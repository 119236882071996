import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto p-12 pt-24 bg-white shadow-md rounded-lg">
      <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>
      <p className="mb-6 text-lg">
        At Latin Transport Inc, we prioritize your privacy and strive to protect your personal information.
      </p>
      <p className="mb-6 text-lg">
        <strong>Data Collection:</strong> We collect data to provide and improve our services. This includes contact details and payment information.
      </p>
      <p className="mb-6 text-lg">
        <strong>Data Usage:</strong> Your data is used strictly for order processing and improving our services. We do not share your information with third parties without your consent.
      </p>
      <p className="mb-6 text-lg">
        <strong>Data Security:</strong> We implement security measures to protect your data from unauthorized access.
      </p>
      <p className="mb-6 text-lg">
        <strong>Policy Updates:</strong> This policy may be updated periodically. We encourage you to review it regularly.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
