import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCart, CartItem } from '../CartContext';
import { useLocation, useNavigate, Link } from 'react-router-dom';

interface Material {
  id: number;
  name: string;
  price: number;
  description: string;
  image: string;
}

interface LocationState {
  distance: number;
  customerZipCode: string;
}

const Order: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { distance, customerZipCode } = location.state as LocationState || {};
  const query = new URLSearchParams(location.search);
  const initialMaterialName = query.get('material') || 'Gravel';
  
  const [materials, setMaterials] = useState<Material[]>([]);
  const [materialName, setMaterialName] = useState<string>(initialMaterialName);
  const { addToCart } = useCart();
  const [quantity, setQuantity] = useState(12);
  const [deliveryMethod, setDeliveryMethod] = useState<'pickup' | 'delivery'>('pickup');
  const [showNotification, setShowNotification] = useState(false);
  const [pricePerMile, setPricePerMile] = useState<number | null>(null);

  useEffect(() => {
    if (!customerZipCode) {
      navigate('/zip-code');
    }
  }, [customerZipCode, navigate]);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/materials`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        });
        const fetchedMaterials = response.data.map((material: any) => ({
          ...material,
          price: Number(material.price),
        }));
        setMaterials(fetchedMaterials);
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    };

    const fetchPricePerMile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/price-per-mile`);
        setPricePerMile(Number(response.data.price));
      } catch (error) {
        console.error('Error fetching price per mile:', error);
        setPricePerMile(0);
      }
    };

    fetchMaterials();
    fetchPricePerMile();
  }, []);

  const handleAddToCart = () => {
    const selectedMaterial = materials.find((material) => material.name === materialName);
    if (selectedMaterial) {
      const material = {
        ...selectedMaterial,
        quantity,
        deliveryMethod,
        customerZipCode,
        deliveryFee: calculateDeliveryFee(),
      };
      addToCart(material as CartItem);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const handleMaterialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMaterialName(e.target.value);
  };

  const calculateDeliveryFee = () => {
    if (deliveryMethod === 'pickup' || !pricePerMile) return 0;
    return Number((distance * pricePerMile).toFixed(2));
  };

  const selectedMaterial = materials.find((material) => material.name === materialName);

  if (!selectedMaterial) {
    return <div>Loading...</div>;
  }

  const basePrice = selectedMaterial.price;
  const deliveryFee = calculateDeliveryFee();
  const subtotal = basePrice * quantity;
  const totalPrice = subtotal + deliveryFee;

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || parseInt(value) < 12) {
      setQuantity(12);
    } else {
      setQuantity(parseInt(value));
    }
  };

  return (
    <div className="min-h-screen bg-gray-300 flex flex-col items-center pt-24">
      {showNotification && (
        <div className="fixed top-16 right-16 bg-blue-500 text-white px-4 py-2 rounded shadow-lg z-50">
          Added to cart!
        </div>
      )}
      <div className="container mx-auto p-8 flex flex-col lg:flex-row bg-white rounded shadow-lg">
        <div className="lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
          <img src={selectedMaterial.image} alt={materialName} className="w-full h-80 object-cover rounded" />
          <div className="flex items-center mt-4">
            <h2 className="text-2xl font-bold text-gray-800">Locally Supplied</h2>
          </div>
          <Link 
            to={`/quantity-calculator?material=${materialName}`}
            className="mt-4 text-blue-500 hover:text-blue-700 underline"
          >
            Need help calculating? Click here to use our calculator
          </Link>
        </div>
        <div className="lg:w-1/2 p-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Order {materialName}</h1>
          <p className="text-gray-700 mb-2">Delivery to: {customerZipCode}</p>
          <p className="text-gray-700 mb-4">Distance: {distance} miles</p>
          
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="material">
            Select Material
          </label>
          <select
            id="material"
            value={materialName}
            onChange={handleMaterialChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          >
            {materials.map((material) => (
              <option key={material.id} value={material.name}>
                {material.name}
              </option>
            ))}
          </select>
          
          <p className="text-gray-700 text-base mb-4">{selectedMaterial.description}</p>
          <p className="text-gray-700 text-base mb-4">Price per ton: ${basePrice.toFixed(2)}</p>
          
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
          Quantity (in tons, minimum 12 tons)
        </label>
        <input
          type="number"
          id="quantity"
          value={quantity}
          onChange={handleQuantityChange}
          onBlur={() => {
            if (quantity < 12) setQuantity(12);
          }}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          min="12"
        />
          
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deliveryMethod">
            Delivery Method
          </label>
          <select
            id="deliveryMethod"
            value={deliveryMethod}
            onChange={(e) => setDeliveryMethod(e.target.value as 'pickup' | 'delivery')}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          >
            <option value="pickup">Pickup</option>
            <option value="delivery">Delivery</option>
          </select>
          
          <div className="bg-gray-100 p-4 rounded-lg mb-4">
            <h3 className="text-lg font-semibold mb-2">Order Summary</h3>
            <div className="flex justify-between mb-2">
              <span>Subtotal:</span>
              <span>${subtotal.toFixed(2)}</span>
            </div>
            {deliveryMethod === 'delivery' && (
              <div className="flex justify-between mb-2">
                <span>Delivery Fee ({distance} miles @ ${pricePerMile ? `${pricePerMile.toFixed(2)}` : 'N/A'}/mile):</span>
                <span>${deliveryFee.toFixed(2)}</span>
              </div>
            )}
            <div className="flex justify-between font-bold">
              <span>Total:</span>
              <span>${totalPrice.toFixed(2)}</span>
            </div>
          </div>
          
          <button
            onClick={handleAddToCart}
            className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition w-full"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Order;