import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRankingStar, faTruckFast, faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import reyexcavatorImage from '../images/Reyexcavator.jpg';
import flatlandImage from '../images/Flatland.jpg';
import redfrontloaderImage from '../images/Redfrontloader.jpg';
import excavator2Image from '../images/Excavator2.jpg';
import frontloaderImage from '../images/Frontloader.jpg';
import truckImage from '../images/Dig.jpg';
import excavatorImage from '../images/Excavator.jpg'; 
import dumptruckImage from '../images/DumpTruck.jpg'; 
import orderImage from '../images/Order.jpg';  

const projectImages = [
  reyexcavatorImage,
  excavator2Image,
  flatlandImage,
  redfrontloaderImage,
  frontloaderImage,
];

const Home: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % projectImages.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="relative h-screen mb-9 overflow-hidden">
        <div className="absolute inset-0 flex transition-transform duration-1000 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {projectImages.map((image, index) => (
            <img key={index} src={image} alt={`Project ${index + 1}`} className="w-full h-full object-cover flex-shrink-0" />
          ))}
        </div>
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black opacity-50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center px-4 border border-white p-6 bg-black bg-opacity-50">
          <div className="text-left text-white bg-opacity-50 max-w-5xl mx-auto">
            <h1 className="text-green-400 text-3xl md:text-4xl mb-2">LATIN TRANSPORT INC PROVIDES</h1>
            <h2 className="text-xl md:text-5xl font-bold mb-2">QUALITY AGGREGATES, EXCAVATION, LAND FLATTENING, AND MORE</h2>
            <p className="text-xl md:text-3xl mb-3">Located In East Bernard, Serving Surrounding Areas</p>
            <p className="text-lg md:text-2xl mb-2 leading-relaxed text-green-400"><FontAwesomeIcon icon={faRankingStar} /> Professional Work</p>
            <p className="text-lg md:text-2xl mb-2 leading-relaxed text-green-400"><FontAwesomeIcon icon={faTruckFast} /> Fast Delivery</p>
            <p className="text-lg md:text-2xl mb-2 leading-relaxed text-green-400"><FontAwesomeIcon icon={faPiggyBank} /> Affordable Prices</p>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 text-center">
        <div className="flex flex-col items-center gap-10">
          <div className="p-10 mb-8 transform transition duration-500 hover:scale-105 w-full md:w-2/3 lg:w-1/2 flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 text-left mb-4 md:mb-0">
              <h2 className="text-5xl font-bold mb-4 text-gray-800">Explore Our Services</h2>
              <p className="text-lg mb-6">We offer a variety of services. Shop for the right material, Land Leveling, Excavation, Tree Clearing, and more.</p>
              <Link to="/services" className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition">Learn more</Link>
            </div>
            <div className="md:w-1/2">
              <div className="relative w-full h-64 bg-cover bg-center rounded-lg shadow-md">
                <img src={truckImage} alt="Service" className="w-full h-full object-cover rounded-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-green-500 py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4 text-black-800">GUARANTEED VALUE</h2>
          <div className="flex flex-col md:flex-row justify-around items-start gap-6">
            <div className="bg-white p-6 rounded-lg shadow-lg md:w-1/3 flex flex-col items-center">
              <img src={dumptruckImage} alt="Reliable Delivery with Care" className="w-16 h-16 mb-4" />
              <h4 className="text-xl font-bold mb-4 text-gray-800">Reliable Delivery with Care</h4>
              <p className="text-lg text-gray-600">We ensure your materials arrive safely and on time. With 100% communication.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg md:w-1/3 flex flex-col items-center">
              <img src={excavatorImage} alt="Top Tier Aggregates for Durable Finishes" className="w-16 h-16 mb-4" />
              <h4 className="text-xl font-bold mb-4 text-gray-800">Top Tier Aggregates for Durable Finishes</h4>
              <p className="text-lg text-gray-600">Our aggregates are of the highest quality, ensuring long-lasting and durable results.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg md:w-1/3 flex flex-col items-center">
              <img src={orderImage} alt="Hassle Free Ordering Online" className="w-16 h-16 mb-4" />
              <h4 className="text-xl font-bold mb-4 text-gray-800">Hassle Free Ordering Online</h4>
              <p className="text-lg text-gray-600">Our user-friendly online ordering system makes it easy to get the materials you need quickly and efficiently.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
