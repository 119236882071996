import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface VerifyPinProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const VerifyPin: React.FC<VerifyPinProps> = ({ setIsAuthenticated }) => {
  const [pin, setPin] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleVerifyPin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        { pin },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const { token } = response.data;
      localStorage.setItem('token', token);
      setMessage('Logged in successfully');
      setIsAuthenticated(true);
      navigate('/admin'); // Redirect to admin page
      console.log('Response data:', response.data);
    } catch (error) {
      console.error('Error verifying pin:', error);
      setMessage('Failed to log in');
      setIsAuthenticated(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="p-8 bg-gray-800 shadow-lg rounded-lg max-w-md w-full">
        <h2 className="text-3xl font-bold text-center text-white mb-6">Admin Login</h2>
        <input
          type="password"
          name="pin"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          placeholder="Enter PIN"
          className="w-full p-3 mb-4 text-lg text-gray-900 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button
          onClick={handleVerifyPin}
          className="w-full p-3 text-lg text-white bg-indigo-500 rounded hover:bg-indigo-600 transition duration-300"
        >
          Verify Pin
        </button>
        {message && <p className="mt-4 text-center text-white">{message}</p>}
      </div>
    </div>
  );
};

export default VerifyPin;
