import React from 'react';

const FAQ: React.FC = () => {
  return (
<div className="container mx-auto p-12 pt-24 bg-white shadow-md rounded-lg">
        <h1 className="text-3xl font-bold mb-4 text-center">FAQ</h1>

      <h2 className="text-2xl font-bold mb-4">1. What materials do you provide?</h2>
      <p className="text-gray-700 mb-4">We provide a variety of aggregates, including gravel, sand, and soil. We also offer excavation and land flattening services.</p>

      <h2 className="text-2xl font-bold mb-4">2. How can I place an order?</h2>
      <p className="text-gray-700 mb-4">You can place an order directly through our website or by contacting our sales team at <a href="mailto:latintransportinc-tx.com" className="text-blue-500">latintransportinc-tx.com</a>.</p>

      <h2 className="text-2xl font-bold mb-4">3. What is your delivery area?</h2>
      <p className="text-gray-700 mb-4">We serve East Bernard and surrounding areas. Please contact us for more details about our delivery range.</p>

      <h2 className="text-2xl font-bold mb-4">4. How can I contact customer support?</h2>
      <p className="text-gray-700 mb-4">You can reach our customer support team at <a href="mailto:latintransportinc-tx.com" className="text-blue-500">latintransportinc-tx.com</a> or call us at (979) 618-8108.</p>
    </div>
  );
};

export default FAQ;
