import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Material {
  id: number;
  name: string;
  price: string;
  image: string;
}

const Calculator: React.FC = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(null);
  const [width, setWidth] = useState('');
  const [length, setLength] = useState('');
  const [depth, setDepth] = useState('');
  const [cubicYards, setCubicYards] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/materials`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setMaterials(response.data);
    } catch (error) {
      console.error('Error fetching materials:', error);
    }
  };

  const handleCalculate = () => {
    const w = parseFloat(width);
    const l = parseFloat(length);
    const d = parseFloat(depth);

    if (isNaN(w) || isNaN(l) || isNaN(d)) {
      alert('Please enter valid dimensions');
      return;
    }

    // Calculate cubic yards
    const cubicFeet = w * l * d; // All dimensions are in feet now
    const cubicYards = cubicFeet / 27; // Convert cubic feet to cubic yards

    setCubicYards(cubicYards.toFixed(2));

    if (selectedMaterial) {
      const price = cubicYards * parseFloat(selectedMaterial.price);
      setPrice(price.toFixed(2));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-full space-y-8">
        <div>
          <h2 className="mt-7 text-center text-3xl font-extrabold text-gray-900">
            Material Calculator
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Calculate the quantity and cost of your materials
          </p>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">How to Measure</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Follow these guidelines for accurate calculations.</p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Width</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Measure the width of the area in feet.</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Length</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Measure the length of the area in feet.</dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Depth</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Measure the depth in feet.</dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Calculation Explanation</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  To calculate the volume in cubic yards, multiply the width by the length and the depth (all in feet). Then divide the result by 27 to convert cubic feet to cubic yards.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <form className="mt-8 space-y-6" onSubmit={(e) => { e.preventDefault(); handleCalculate(); }}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="material" className="sr-only">Material</label>
              <select
                id="material"
                name="material"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                value={selectedMaterial ? selectedMaterial.id : ''}
                onChange={(e) => {
                  const material = materials.find(m => m.id === parseInt(e.target.value));
                  setSelectedMaterial(material || null);
                }}
              >
                <option value="">Select Material</option>
                {materials.map(material => (
                  <option key={material.id} value={material.id}>
                    {material.name} - ${parseFloat(material.price).toFixed(2)} per cubic yard
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="width" className="sr-only">Width (feet)</label>
              <input
                id="width"
                name="width"
                type="number"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Width (feet)"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="length" className="sr-only">Length (feet)</label>
              <input
                id="length"
                name="length"
                type="number"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Length (feet)"
                value={length}
                onChange={(e) => setLength(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="depth" className="sr-only">Depth (feet)</label>
              <input
                id="depth"
                name="depth"
                type="number"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Depth (feet)"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Calculate
            </button>
          </div>
        </form>

        {cubicYards && price && (
          <div className="mt-8">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Results</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Cubic Yards</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{cubicYards}</dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">Total Price</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">${price}</dd>
              </div>
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calculator;
