// src/components/Contact.tsx
import React from 'react';
import ContactForm from './ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot, faClock } from '@fortawesome/free-solid-svg-icons';
import officeImage from '../images/Office.jpg';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Contact: React.FC = () => {
  return (
    <div
      className="w-full min-h-screen flex flex-col items-center justify-center bg-cover bg-center pt-24"
      style={{ backgroundImage: `url(${officeImage})` }}
    >
      <div className="w-full max-w-3xl mx-auto text-center bg-white bg-opacity-75 rounded-xl shadow-lg p-3">
        <h1 className="text-4xl font-bold mb-6 text-black">Contact Us</h1>
        <p className="text-2xl mb-3 text-black">Have any questions? Let us help you!</p>
        <div className="w-full flex flex-col md:flex-row items-center justify-between p-4 rounded-lg">
          <div className="w-full md:w-1/2 mb-6 md:mb-0 p-4 md:border-r-2 border-gray-300 text-left">
            <h2 className="text-2xl font-bold mb-3 text-gray-800">Our Contact Information</h2>
            <div className="mb-3 flex flex-col items-start">
              <p className="text-lg text-gray-700 flex items-center mb-2">
                <FontAwesomeIcon icon={faLocationDot} className="mr-2" />
                <strong>Office:</strong>
              </p>
              <p className="text-gray-700">5847 Behrens Rd, East Bernard, TX 77435, United States</p>
            </div>
            <div className="mb-3 flex flex-col items-start">
              <p className="text-lg text-gray-700 flex items-center mb-2">
                <FontAwesomeIcon icon={faPhone} className="mr-2" />
                <strong>Phone:</strong>
              </p>
              <p className="text-gray-700">239-214-4919</p>
            </div>
            <div className="mb-3 flex flex-col items-start">
              <p className="text-lg text-gray-700 flex items-center mb-2">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                <strong>Email:</strong>
              </p>
              <p className="text-gray-700">Latintransportinc@yahoo.com</p>
            </div>
            <div className="mb-3 flex flex-col items-start">
              <p className="text-lg text-gray-700 flex items-center mb-2">
                <FontAwesomeIcon icon={faClock} className="mr-2" />
                <strong>Office Hours:</strong>
              </p>
              <p className="text-gray-700">Mon - Fri 9 AM to 5 PM</p>
            </div>
            <div className="mb-6 md:w-1/3 flex justify-center">
            <a href="https://www.facebook.com/reynaldo.remedios.792" className="text-blue-500 hover:text-gray-400 mx-4">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.linkedin.com/in/anel-garduno-64a829a8/" className="text-blue-400 hover:text-gray-400 mx-4">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
          </div>
          <div className="w-full md:w-1/2 p-4 text-left">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Contact Form</h2>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
