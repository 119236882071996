// src/components/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <div className="w-full bg-gray-800 py-12">
      <div className="container mx-auto text-white">
        <div className="flex flex-col md:flex-row justify-center items-center text-center gap-6">
          <div className="md:w-1/3">
            <h4 className="text-xl font-bold mb-4">Legal</h4>
            <ul>
              <li className="mb-2"><Link to="/terms-of-service" className="hover:underline">Terms of Service</Link></li>
              <li className="mb-2"><Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link></li>
              <li className="mb-2"><Link to="/refund-policy" className="hover:underline">Refund Policy</Link></li>
            </ul>
          </div>
          <div className="md:w-1/3">
            <h4 className="text-xl font-bold mb-4">Other Information</h4>
            <ul>
              <li className="mb-2"><Link to="/faq" className="hover:underline">FAQ</Link></li>
            </ul>
          </div>
          <div className="md:w-1/3 flex justify-center">
            <a href="https://www.facebook.com/reynaldo.remedios.792" className="text-blue-500 hover:text-gray-400 mx-4">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.linkedin.com/in/anel-garduno-64a829a8/" className="text-blue-400 hover:text-gray-400 mx-4">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;