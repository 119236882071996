import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FlatDriveway from '../images/FlatDriveway.jpg';
import FlatLand1 from '../images/FlatLand1.jpg';
import FlatLand3 from '../images/FlatLand3.jpg';

const images = [
  { src: FlatDriveway, alt: 'Flat Driveway' },
  { src: FlatLand1, alt: 'Flat Land 1' },
  { src: FlatLand3, alt: 'Flat Land 3' },
];

const LandLeveling: React.FC = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <div className="min-h-screen bg-gray-300 flex flex-col items-center pt-24">
      <div className="container mx-auto p-8">
        <h1 className="text-5xl font-bold mb-6 text-gray-900 text-center">House Padding Services</h1>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 flex flex-col items-center">
            <div className="relative w-full max-w-lg">
              <button onClick={handlePrevImage} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-gray-900 transition">
                &lt;
              </button>
              <div className="w-full h-96 flex items-center justify-center overflow-hidden border-4 border-white rounded-lg shadow-lg">
                <img src={images[selectedImageIndex].src} alt={images[selectedImageIndex].alt} className="w-full h-full object-cover rounded-lg" />
              </div>
              <button onClick={handleNextImage} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full hover:bg-gray-900 transition">
                &gt;
              </button>
            </div>
            <div className="flex mt-4 space-x-2">
              {images.map((image, index) => (
                <div key={index} className={`w-16 h-16 overflow-hidden cursor-pointer ${index === selectedImageIndex ? 'border-2 border-blue-500' : ''}`} onClick={() => setSelectedImageIndex(index)}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className={`w-full h-full object-cover rounded-lg transition-transform duration-300 ${index === selectedImageIndex ? 'transform scale-110' : 'opacity-50'}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-1/2 md:pl-6 mt-8 md:mt-0">
            <p className="text-lg text-gray-900 mb-2">
              House padding is a process that ensures a smooth and even surface, which is essential for various applications such as:
            </p>
            <ul className="list-disc list-inside text-gray-900 mb-2">
              <li>Preparing land for construction projects</li>
              <li>Creating a stable base for driveways and walkways</li>
              <li>Improving water drainage and irrigation efficiency</li>
              <li>Reducing soil erosion</li>
              <li>Enhancing the aesthetic appeal of your property</li>
            </ul>
            <p className="text-lg text-gray-900 mb-6">
              Our professional house padding services are designed to meet your specific needs, ensuring quality and satisfaction.
            </p>
            <Link to="/contact" className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition duration-300">
              Need a Quote? Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandLeveling;
