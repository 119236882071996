import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBars, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../CartContext';
import logo from "../images/logo.jpg"; // Update the path if necessary

const Navbar: React.FC<{ onCartToggle: () => void }> = ({ onCartToggle }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const mobileDropdownRef = useRef<HTMLDivElement>(null);
  const { cart } = useCart();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
      if (mobileDropdownRef.current && !mobileDropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-green-500 bg-opacity-80 p-4 fixed w-full z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" onClick={handleLinkClick} className="flex items-center space-x-2">
          <img src={logo} alt="LTI Logo" className="h-10 w-auto object-contain drop-shadow-lg" />
          <span className="text-white text-1xl font-bold">LTI</span>
        </Link>
        <ul className="hidden md:flex space-x-4 items-center">
          <li>
            <Link to="/" className="text-white hover:text-gray-300 px-3 py-2 text-2xl" onClick={handleLinkClick}>Home</Link>
          </li>
          <li
            className="relative"
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={(e) => {
              const relatedTarget = e.relatedTarget as Node;
              if (!dropdownRef.current?.contains(relatedTarget)) {
                setIsDropdownOpen(false);
              }
            }}
          >
            <Link to="/services" className="text-white hover:text-gray-300 px-4 py-3 text-2xl" onClick={handleLinkClick}>Services</Link>
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute mt-0 bg-green-400 text-white rounded shadow-lg"
              >
                <div className="relative">
                  <div className="pl-6">
                    <Link to="/materials" className="block px-4 py-2 hover:bg-green-300 text-2xl" onClick={handleLinkClick}>Materials</Link>
                    <Link to="/land-leveling" className="block px-4 py-2 hover:bg-green-300 text-2xl" onClick={handleLinkClick}>Land Leveling</Link>
                    <Link to="/tree-clearing" className="block px-4 py-2 hover:bg-green-300 text-2xl" onClick={handleLinkClick}>Tree Clearing</Link>
                    <Link to="/retention-pond" className="block px-4 py-2 hover:bg-green-300 text-2xl" onClick={handleLinkClick}>Retention Ponds</Link>
                    <Link to="/carrier-service" className="block px-4 py-2 hover:bg-green-300 text-2xl" onClick={handleLinkClick}>Carrier Information</Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li>
            <Link to="/quantity-calculator" className="text-white hover:text-gray-300 px-3 py-2 text-2xl" onClick={handleLinkClick}>Calculator</Link>
          </li>
          <li>
            <Link to="/contact" className="text-white hover:text-gray-300 px-3 py-2 text-2xl" onClick={handleLinkClick}>Contact</Link>
          </li>
          <li>
            <Link to="/about" className="text-white hover:text-gray-300 px-3 py-2 text-2xl" onClick={handleLinkClick}>About</Link>
          </li>
          <li className="relative">
            <button onClick={onCartToggle} className="text-white hover:text-gray-300 px-3 py-2 relative">
              <FontAwesomeIcon icon={faShoppingCart} className="text-3xl" />
              {cart.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
                  {cart.length}
                </span>
              )}
            </button>
          </li>
        </ul>
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white hover:text-gray-300 focus:outline-none"
          >
            <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} className="text-2xl" />
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <ul className="bg-green-500 bg-opacity-80 p-3 space-y-1">
            <li>
              <Link to="/" className="text-white hover:text-gray-300 block px-3 py-2" onClick={handleLinkClick}>Home</Link>
            </li>
            <li className="relative flex items-center justify-between">
              <Link to="/services" className="text-white hover:text-gray-300 block px-3 py-2" onClick={handleLinkClick}>Services</Link>
              <div className="flex items-center">
                {!isDropdownOpen && (
                  <button
                    onClick={handleDropdownToggle}
                    className="text-white focus:outline-none ml-2"
                  >
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                )}
                {isDropdownOpen && (
                  <button
                    onClick={handleDropdownToggle}
                    className="text-white focus:outline-none ml-2"
                  >
                    <FontAwesomeIcon icon={faChevronUp} />
                  </button>
                )}
              </div>
            </li>
            {isDropdownOpen && (
              <div ref={mobileDropdownRef} className="bg-green-400 text-white rounded shadow-lg mt-1">
                <div className="pl-1">
                  <Link to="/materials" className="block px-4 py-2 hover:bg-green-300" onClick={handleLinkClick}>Materials</Link>
                  <Link to="/land-leveling" className="block px-4 py-2 hover:bg-green-300" onClick={handleLinkClick}>Land Leveling</Link>
                  <Link to="/tree-clearing" className="block px-4 py-2 hover:bg-green-300" onClick={handleLinkClick}>Tree Clearing</Link>
                  <Link to="/retention-pond" className="block px-4 py-2 hover:bg-green-300" onClick={handleLinkClick}>Retention Ponds</Link>
                  <Link to="/carrier-service" className="block px-4 py-2 hover:bg-green-300" onClick={handleLinkClick}>Carrier Information</Link>
                </div>
              </div>
            )}
          <li>
              <Link to="/contact" className="text-white hover:text-gray-300 block px-3 py-2" onClick={handleLinkClick}>Calculator</Link>
          </li>
            <li>
              <Link to="/contact" className="text-white hover:text-gray-300 block px-3 py-2" onClick={handleLinkClick}>Contact</Link>
            </li>
            <li>
              <Link to="/about" className="text-white hover:text-gray-300 block px-3 py-2" onClick={handleLinkClick}>About</Link>
            </li>
            <li className="relative">
              <button onClick={onCartToggle} className="text-white hover:text-gray-300 block px-3 py-2 relative">
                <FontAwesomeIcon icon={faShoppingCart} className="text-2xl" />
                {cart.length > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
                    {cart.length}
                  </span>
                )}
              </button>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
