// src/components/Services.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import landLevelingImage from '../images/LandLeveling.jpg';
import treeClearingImage from '../images/TreeClearing.jpg';
import retentionPondImage from '../images/RetentionPond.jpg';
import carrierServiceImage from '../images/CarrierService.jpg';
import materialsImage from '../images/Gravel.jpg';

const services = [
  {
    name: 'Materials',
    description: 'High-quality materials for all your construction needs.',
    image: materialsImage,
    link: '/materials'
  },
  {
    name: 'House Padding',
    description: 'Professional land leveling services to prepare your site for any construction project.',
    image: landLevelingImage,
    link: '/land-leveling'
  },

  {
    name: 'Land Clearing',
    description: 'Efficient and safe tree clearing services for your land development needs.',
    image: treeClearingImage,
    link: '/tree-clearing'
  },
  {
    name: 'Retention Pond Service',
    description: 'Specialized retention pond services to manage water runoff and improve land drainage.',
    image: retentionPondImage,
    link: '/retention-ponds' // Corrected path
  },
  {
    name: 'Carrier Service',
    description: 'Reliable carrier services for transporting materials and loads.',
    image: carrierServiceImage,
    link: '/carrier-service'
  }
];

const Services: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-300 flex flex-col items-center pt-24">
      <div className="container mx-auto p-8">
        <h1 className="text-5xl font-bold mb-8 text-gray-800 text-center">Our Services</h1>
        <div className="flex flex-wrap justify-center gap-8">
          {services.map((service, index) => (
            <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
              <img className="w-full h-64 object-cover" src={service.image} alt={service.name} />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-gray-800">{service.name}</div>
                <p className="text-gray-700 text-base">{service.description}</p>
              </div>
              <div className="px-6 pt-4 pb-2 text-center">
                <Link to={service.link} className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition">Learn more</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
