import React from 'react';
import { useCart } from '../CartContext';


interface CartPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onCheckout: () => void;
}

const CartPopup: React.FC<CartPopupProps> = ({ isOpen, onClose, onCheckout }) => {
  const { cart, removeFromCart, getTotal } = useCart();


  const handleCheckout = () => {
    onClose();
    onCheckout();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose}></div>
      )}
      <div className={`fixed top-16 right-0 h-full md:w-1/3 w-full bg-white shadow-lg transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} z-50`}>
        <div className="p-4 relative">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none absolute top-4 right-4"
          >
            &times;
          </button>
          <h2 className="text-2xl font-bold mb-4">Cart</h2>
          {cart.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <>
              <ul>
                {cart.map((item, index) => (
                  <li key={index} className="flex justify-between items-center mb-4">
                    <div className="flex items-center space-x-4">
                      <img src={item.image} alt={item.name} className="w-16 h-16 object-cover rounded" />
                      <div>
                        <p className="font-bold">{item.name}</p>
                        <p>Quantity: {item.quantity}</p>
                        <p>Price: ${item.price}</p>
                        <p>Delivery Method: {item.deliveryMethod}</p>
                        {item.deliveryMethod === 'delivery' && (
                          <p>Delivery Fee: ${typeof item.deliveryFee === 'number' ? item.deliveryFee.toFixed(2) : item.deliveryFee}</p>
                        )}
                        {item.customerZipCode && <p>Zip Code: {item.customerZipCode}</p>}
                      </div>
                    </div>
                    <button
                      onClick={() => removeFromCart(index)}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      &times;
                    </button>
                  </li>
                ))}
              </ul>
              <p className="font-bold text-xl mt-4">Total: ${getTotal().toFixed(2)}</p>
              <button
                onClick={handleCheckout}
                className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition w-full mt-4"
              >
                Proceed to Checkout
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CartPopup;