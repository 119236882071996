import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import axios from 'axios';

export interface CartItem {
  id: number;
  name: string;
  price: number;
  description: string;
  image: string;
  quantity: number;
  deliveryMethod: 'pickup' | 'delivery';
  customerZipCode?: string;
  deliveryFee?: number | string;
}

interface CartState {
  cart: CartItem[];
}

interface CartAction {
  type: string;
  payload?: any;
}

const initialState: CartState = {
  cart: [],
};

interface CartContextType {
  cart: CartItem[];
  removeFromCart: (index: number) => void;
  addToCart: (item: CartItem) => void;
  updateQuantity: (index: number, quantity: number) => void;
  updateDeliveryMethod: (index: number, deliveryMethod: 'pickup' | 'delivery') => void;
  updateDeliveryFee: (index: number, deliveryFee: number | string) => void;
  checkout: () => Promise<string>;
  getTotal: () => number;
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case 'ADD_TO_CART':
      return { ...state, cart: [...state.cart, action.payload] };
    case 'REMOVE_FROM_CART':
      return { ...state, cart: state.cart.filter((_, index) => index !== action.payload) };
    case 'UPDATE_QUANTITY':
      return {
        ...state,
        cart: state.cart.map((item, index) =>
          index === action.payload.index ? { ...item, quantity: action.payload.quantity } : item
        ),
      };
    case 'UPDATE_DELIVERY_METHOD':
      return {
        ...state,
        cart: state.cart.map((item, index) =>
          index === action.payload.index ? { ...item, deliveryMethod: action.payload.deliveryMethod } : item
        ),
      };
    case 'UPDATE_DELIVERY_FEE':
      return {
        ...state,
        cart: state.cart.map((item, index) =>
          index === action.payload.index ? { ...item, deliveryFee: action.payload.deliveryFee } : item
        ),
      };
    case 'CLEAR_CART':
      return { ...state, cart: [] };
    default:
      return state;
  }
};

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addToCart = (item: CartItem) => {
    dispatch({ type: 'ADD_TO_CART', payload: item });
  };

  const removeFromCart = (index: number) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: index });
  };

  const updateQuantity = (index: number, quantity: number) => {
    dispatch({ type: 'UPDATE_QUANTITY', payload: { index, quantity } });
  };

  const updateDeliveryMethod = (index: number, deliveryMethod: 'pickup' | 'delivery') => {
    dispatch({ type: 'UPDATE_DELIVERY_METHOD', payload: { index, deliveryMethod } });
  };

  const updateDeliveryFee = (index: number, deliveryFee: number | string) => {
    dispatch({ type: 'UPDATE_DELIVERY_FEE', payload: { index, deliveryFee } });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  const getTotal = (): number => {
    return state.cart.reduce((sum: number, item: CartItem) => {
      const itemTotal = item.price * item.quantity;
      const deliveryFee = item.deliveryMethod === 'delivery' && typeof item.deliveryFee === 'number' ? item.deliveryFee : 0;
      return sum + itemTotal + deliveryFee;
    }, 0);
  };

  const checkout = async (): Promise<string> => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-payment-intent`, {
      items: state.cart,
      total: getTotal(),
    });
    return data.clientSecret;
  };

  const contextValue: CartContextType = {
    cart: state.cart,
    addToCart,
    removeFromCart,
    updateQuantity,
    updateDeliveryMethod,
    updateDeliveryFee,
    checkout,
    getTotal,
    clearCart
  };

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};